import { css } from '@emotion/react'
import React, { Component } from "react"
import { CardElevation,ButtonDegrade} from '@findep/mf-landings-core'
import { navigate } from "../../helpers/queryNavigate"
import { CataloguesService } from '../../services/LandingV4'
import { withThemeProps } from '@findep/microfronts-core';
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography';
import { Grid, Container,Backdrop } from "@material-ui/core"
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { getPathById } from "../../helpers/array"
import { ScoreServiceAfi } from '../../services/LandingV4/scoreAfi'
import CanvasScreenShot from './afi/canvasScreenShot'
import CircularProgress from '@material-ui/core/CircularProgress';
import formatNumber from '../../helpers/formatNumber'

const bDrop = css`  
    z-index:9999;
    color: '#fff';
  `
const textHeaderMain = css`        
    text-align: center;        
    color: #61ce70;
    font-size: 28px; 
`
const textHeader = css`        
    text-align: center; 
    color: #131413;
    font-size: 20px; 
`

class PassFicoView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            monto: '$4,0000',
            btn_active: false
        }

    } 

    async componentDidMount() {        
        let amount = await  JSON.parse(sessionStorage.getItem('amount-soft')) || '00'
        this.setState({monto: amount})             
    }

    nextStep=()=>{     
        this.setState({ back_drop_active: true })           
        let data=JSON.parse(sessionStorage.getItem('data-complementary')) || null
        let flujo = JSON.parse(sessionStorage.getItem('flujo'))
        let navigateUrl = getPathById(this.props.pageContext?.errorPath,'legacy')        
        const scoreSrv = new ScoreServiceAfi(flujo.creditApplicationId)
        scoreSrv.sendPassFico(data)
          .then((response) => {            
            if (response.data.preapproved) {
              flujo = {
                ...flujo,
                datosAdicionales: response.data
              }
              sessionStorage.setItem('flujo', JSON.stringify(flujo))
              navigateUrl =this.props.pageContext?.next?.path          
            }else{
              flujo = {
                ...flujo,
                datosAdicionales: response.data
              }
              sessionStorage.setItem('flujo', JSON.stringify(flujo))
            //   navigate(navigateUrl)
            }
            
            if(response.data?.blackList){
                navigateUrl=getPathById(this.props.pageContext.errorPath,'blacklist')
            }
            CanvasScreenShot(flujo.creditApplicationId, 2);
            localStorage.clear();
            sessionStorage.removeItem('data-complementary');
            navigate(navigateUrl)
          })
          .catch(error => {
            console.log("error", error);
            this.setState({ errorService: true, btnSubmit: true, bdHabilita: false })
          })
    }

    componentDidUpdate(prevProp, prevState) {

    }

    async submitStep() {
        const catalogues = new CataloguesService();
        this.setState({ btnSubmit: false, bdHabilita: true })
        
        let flujo = JSON.parse(sessionStorage.getItem('flujo'))
        const { requerir_prestamo,
          nombre_empresa,
          telefono_empresa,
          actividad_empresa,
          otros_pagos,
          telefono_casa,
          numero_ssn,
          numero_itin,
          renta,
          cantidad,
          trabajo_actual,
          tienes_auto,
          pagoAuto,
          domAnterior,
          rentaAct,
          rentaCasaPago,
          trabajo_anterior,
          domActual,
          paydayMount,
          creditPayday,
          usArmyForce,
          estado_civil,
          job_position,
          gender,
          race,
          ethnicity,
          head_household
        } = this.state
        
        if(cantidad!=="" && cantidad <= 1999){     
          await catalogues.postIngresoMinimo(flujo.creditApplicationId) 
          navigate(getPathById(this.props.pageContext?.errorPath,'low-cost'))
        }else{
    
        let pagaAuto = pagoAuto === "" ? 0 : pagoAuto;
        let rentaCasaPagoEdit = rentaCasaPago === "" ? 0 : rentaCasaPago;
        let hCar = tienes_auto === "S" ? true : false
        let rntAct = renta === "S" ? 'RENTER' : 'OWNER' 
        let navigateUrl = getPathById(this.props.pageContext?.errorPath,'legacy')
        let cPayday = creditPayday === "S" ? true : false
        let armyForce = usArmyForce === "S" ? true : false
        let cpaydayMount = paydayMount === "" ? 0 : paydayMount    
    
        const scoreSrv = new ScoreServiceAfi(flujo.creditApplicationId)
        scoreSrv.setBasicProfile(gender, race, ethnicity, head_household)
        scoreSrv.setMaritalStatusId(estado_civil)
        scoreSrv.setOccupationId(job_position)
        scoreSrv.setIncomeSourceId("AFI")
        scoreSrv.setIncome(parseInt(cantidad))
        scoreSrv.setExpenses(parseInt(1))
        scoreSrv.setHomeSinceTotalMonths(parseInt(domActual))
        scoreSrv.setDependents(0)
        scoreSrv.setJobSinceTotalMonths(parseInt(trabajo_actual))
        scoreSrv.setWorkPhoneNumber(telefono_empresa)
        scoreSrv.setAlternatePhoneNumber(telefono_casa)
    
        scoreSrv.setHasCar(hCar)
        scoreSrv.setCarExpenses(parseInt(pagaAuto))
        scoreSrv.setPreviousHomeSinceTotalMonths(parseInt(domAnterior))
        scoreSrv.setPropertyOwnership(rntAct)
        scoreSrv.setPropertyOwnershipExpenses(parseInt(rentaCasaPagoEdit))
        scoreSrv.setPreviousJobSinceTotalMonths(parseInt(trabajo_anterior))
    
        scoreSrv.setCreditDestination(requerir_prestamo)
        scoreSrv.setBusinessName(nombre_empresa)
        scoreSrv.setBusinessPhone('')
        scoreSrv.setBusinessLine(actividad_empresa)
        scoreSrv.setOtherExpensePayment(parseInt(otros_pagos))
        scoreSrv.setLandlineHomePhone('')
        scoreSrv.setSsn(numero_ssn)
        scoreSrv.setItin(numero_itin)
    
        scoreSrv.setpayDayLoan(cpaydayMount)
        // scoreSrv.setcreditPayday(cPayday)
        scoreSrv.setusArmyForces(armyForce)
    
        scoreSrv.send()
          .then((response) => {
            // console.log("reponse", response);
            // this.saveGtmcolor(response.data?.color)
            if (response.data.preapproved) {
              flujo = {
                ...flujo,
                datosAdicionales: response.data
              }
              sessionStorage.setItem('flujo', JSON.stringify(flujo))
              navigateUrl =this.props.pageContext?.next?.path          
            }else{
              flujo = {
                ...flujo,
                datosAdicionales: response.data
              }
              sessionStorage.setItem('flujo', JSON.stringify(flujo))
            }
            // console.log("response.data?.blackList=>",response.data?.blackList)
            if(response.data?.blackList){
                navigateUrl=getPathById(this.props.pageContext.errorPath,'blacklist')
            }
            CanvasScreenShot(this.state.flujo.creditApplicationId, 2);
            localStorage.clear();
            navigate(navigateUrl)
          })
          .catch(error => {
            console.log("error", error);
            this.setState({ errorService: true, btnSubmit: true, bdHabilita: false })
          })
        }
        // let navigateUrl =this.props.pageContext?.next?.path 
        // navigate(navigateUrl)
      }

      async sigPantalla(){
        this.setState({ back_drop_active: true })      
        let navigateUrl =this.props.pageContext?.next?.path     
        navigate(navigateUrl)
      }
 

    render() {   
        const{monto}=this.state     
        const { 
            lblFelicidades="¡FELICIDADES!",
            lblConbase="Con base en la información que nos proporcionaste en tu solicitud de préstamo, nos complace informarte que has sido precalificado para un préstamo de Apoyo Financiero, Inc.",
            lblmonto="Por un monto de",
            lblContinuar="Si deseas continuar con el proceso de solicitud de préstamo, haz clic en ",
            btnContinuar="Continuar",
            lblTenercuenta="*Ten en cuenta que esta precalificación es solo una evaluación inicial basada en la información que has proporcionado y está sujeta a una revisión completa de tu información, documentación y aprobación crediticia final."

         } = this.props
        

        return (
            <Container justify="center" maxWidth="sm">
                <Grid container justify="center">                    
                    <CardElevation>
                        <Grid container spacing={2} justify="center">
                            {/* <Grid item xs={12} style={{ textAlign: "center" }}>
                                <Typography  component='h1' css={textHeaderMain}>
                                    <b>{lblFelicidades}</b>
                                </Typography>                                
                            </Grid> */}
                            
                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                <Typography  component='h1' css={textHeader}>
                                    {lblConbase}
                                </Typography>
                            </Grid>
                            
                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                <br/>
                                <Typography  component='h1' css={textHeader}>
                                    {lblmonto}<br/>
                                    <b>{formatNumber(monto)}</b> 
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                <Typography  component='h1' css={textHeader}>
                                    {lblContinuar}
                                </Typography>
                                <ButtonDegrade
                                    id="pass-fico-continuar-solicitud-button"
                                    icon={ArrowForwardIcon}
                                    textButton={btnContinuar}
                                    onClick={() => this.sigPantalla()}
                                    // disabled={!this.state.btnSubmit}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                <Typography  component='h1' css={textHeader}>
                                    {lblTenercuenta}                                    
                                </Typography>
                            </Grid>
                            <br/>&nbsp;
                        </Grid>
                        
                    </CardElevation>
                    <br/>&nbsp;
                    <Backdrop css={bDrop} open={this.state.back_drop_active} >
                        <CircularProgress color="primary" />
                    </Backdrop>
                </Grid>
            </Container>
        )
    }
}


export default withThemeProps(PassFicoView, 'VDPNPassFico')



